.uploadsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 172px);
  gap: 15px;
  max-width: 800px;
  margin-bottom: 20px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  gap: 15px;
}

.error {
  color: #d32f2f;
  margin-bottom: 20px;
  padding: 5px 10px;
  background: #ffebee;
  border-radius: 4px;
}
